<template>
  <div id="app">
    <message-controller></message-controller>
      <the-navbar ></the-navbar>
        <div class="main">
            <router-view/>
        </div>
    <the-footer></the-footer>
  </div>
</template>

<script>


import MessageController from "./components/message-controller";
import TheNavbar from "./components/the-navbar";
import TheFooter from "@/components/the-footer";

export default {
  name: 'App',
  components: {
      TheFooter,
    TheNavbar,
    MessageController
  },
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLogged},
  },
  created() {

  },
  async beforeCreate() {
      await this.$store.dispatch('loadDataLangs');
      await this.$store.dispatch('loadStates');
  }
  
}
</script>

<style lang="scss">
  @import "styles/screen.scss";
  @import 'node_modules/bootstrap-vue/src/index.scss';

    .main {
        min-height: calc(100vh - 232px);
        
    }

</style>
