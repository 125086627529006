<template>
    <div class="c-footer">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center mb-5" >
                    <b v-html="$t('footer.text')"></b>
                </div>
                <div class="col-12 c-footer__logo">
                  <img v-if="local == 'cs'"  :src=" path + 'assets/img/Logo_RGB_Color_WEB_CZ.png'" alt="">
                  <img v-else :src=" path + 'assets/img/Logo_RGB_Color_WEB_DE.png'" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "the-footer",
    data() {
        return {
            path: process.env.VUE_APP_SERVER,

        }
    },
  computed: {
    local: function () {
      return this.$store.getters.local;
    }
  }
}
</script>

<style scoped lang="scss">
    .c-footer {
        width: 100%;
        background-color: #008fd7;
        color: white;
        padding-top: 00px;
        padding-bottom: 30px;
        margin-top: 40px;
        a {
            color: white;
            &:hover {
                color: white;
             }
        }
        &__row {
            img {
                margin: 10px;
            }
        }
        &__logo {
            display: flex;
          justify-content: center;
            @media (max-width: 768px) {
                order: -1;
            }
            img {
                max-width: 330px;
                margin: 20px;
            }
        }
    }
</style>
