import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import i18n from "@/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        lang: [],
        states: [],
        status: '',
        user : function () {
            if (localStorage.getItem('user') == undefined || localStorage.getItem('user') == 'undefined') {
                localStorage.removeItem('user');
                return null;
            } else {
                return JSON.parse(localStorage.getItem('user')) || null;
            }
        }(),
        usersRole: [
            { value: 'admin'},
            { value: 'user'},
            { value: 'read-only'},
            { value: 'meetings-only'},
        ],
        colorTheme: true,
        local: ''
    },
    actions: {
        loadDataLangs({commit}){
            Axios({
                url: process.env.VUE_APP_SERVER + 'api/lang/list.php',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                method: 'GET',
                withCredentials: true
            })
                .then((response) => {
                    commit('loadLang', response.data.records);
                    setTimeout( () => {
                        this.dispatch('setLocalizationInServer', i18n.locale);
                    }, 2000 )

            })
        },
        loadStates({commit}){
            Axios({
                url: process.env.VUE_APP_SERVER + 'api/states/list.php',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                method: 'GET',
                withCredentials: true
            })
            .then((response) => {
                commit('loadStates', response.data);
            })
        },
        setLocalizationInServer({commit}, data){
            Axios({
                url: process.env.VUE_APP_SERVER + 'api/lang/set_server_localization.php',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {'local' : i18n.locale},
                method: 'PUT',
                withCredentials: true
            })
            .then(() => {
                commit('local', data);
            })
        },
        login({commit}, user){
            return new Promise((resolve, reject) => {
                Axios({url: process.env.VUE_APP_SERVER + 'api/users/login.php',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data: user,
                    method: 'POST',
                    withCredentials: true
                })
                .then(response => {
                    const user = response.data.data;
                    localStorage.setItem('user', JSON.stringify(user));
                    commit('auth_success', {
                        user: user
                    });
                    resolve();
                })
                .catch(err => {
                    commit('auth_error', err);
                    localStorage.removeItem('user');
                    reject();
                })
            })
        },
        logout({commit}){
            return new Promise((resolve, reject) => {
                Axios({url: process.env.VUE_APP_SERVER + 'api/users/logout.php',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    method: 'DELETE',
                    withCredentials: true
                })
                .then(response => {
                    commit('logout');
                    localStorage.removeItem('user');
                    console.log(response);
                    resolve();
                })
                .catch(err => {
                    commit('logout');
                    localStorage.removeItem('user');
                    console.log(err);
                    reject();
                })
            })
        },
    },
    mutations: {
        loadLang(state, data) {
            state.lang = data;
        },
        loadStates(state, data) {
            state.states = data;
        },
        local(state, data) {
            state.local = data;
        },
        auth_success(state, payload){
            state.status = 'success';
            state.user = payload.user;
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = '';
            state.user = null;
        },

    },
    getters: {
        isLogged: state => {
            return state.user;
        },
        user: state => state.user,
        local: state => state.local,
        langs: state => state.lang,
        states: state => state.states,
        getUserRoles: state => {
            return state.usersRole;
        },
        getColorTheme: state => {
            return state.colorTheme;
        }
    }
})
